<template>
  <div class="domesticDetail">
    <!-- <Icon type="ios-redo"
          class="goback"
          @click="back" />-->
    <div v-if="tab == 1" style="margin-bottom: 50px">
      <h1>{{ entity.zqjc }}({{ entity.zqdm }})</h1>
      <div class="title">基本信息</div>
      <div>
        <div class="table-title">{{ entity.zqqc }}</div>
        <cTableItem
          :titleArr="['债券简称', '债券代码']"
          :contentArr="[entity.zqjc, entity.zqdm]"
        ></cTableItem>

        <cTableItem
          :titleArr="['ISIN码', '原始权益人']"
          :contentArr="[entity.isinm, entity.ysqyr]"
        ></cTableItem>
        <cTableItem
          :titleArr="['发行人', '发行人简称']"
          :contentArr="[entity.fxr, entity.fxrjc]"
        ></cTableItem>
        <cTableItem
          :titleArr="['发行日期', '起息日期']"
          :contentArr="[entity.fxrq, entity.qxrq]"
        ></cTableItem>
        <cTableItem
          :titleArr="['流通范围', '到期日期']"
          :contentArr="[entity.ltfw, entity.dqrq]"
        ></cTableItem>
        <cTableItem
          :titleArr="['上市日期', '流通标志']"
          :contentArr="[entity.ssrq, entity.ltbz]"
        ></cTableItem>
        <cTableItem
          :titleArr="['流通场所', '债券期限']"
          :contentArr="[entity.ltcs, entity.zqqx]"
        ></cTableItem>
        <cTableItem
          :titleArr="['币种', '债券摘牌日']"
          :contentArr="[entity.bz, entity.zqzpr]"
        ></cTableItem>
        <cTableItem
          :titleArr="['发行价格（元）', '面值(元)']"
          :contentArr="[entity.fxjg, entity.mz]"
        ></cTableItem>
        <cTableItem
          :titleArr="['计划发行总额（亿元）', '发行量（万张）']"
          :contentArr="[entity.jhfxze, entity.fxl]"
        ></cTableItem>
        <cTableItem
          :titleArr="['债券类型', '实际发行总额（亿元）']"
          :contentArr="[entity.zqlx, entity.sjfxze]"
        ></cTableItem>
        <cTableItem
          :titleArr="['信用评级机构一', '债券/主体评级一']"
          :contentArr="[entity.xypjjgy, entity.zxZtpjy]"
        ></cTableItem>
        <cTableItem
          :titleArr="['信用评级机构二', '债券/主体评级二']"
          :contentArr="[entity.xypjjge, entity.zxZtpje]"
        ></cTableItem>
        <cTableItem
          :titleArr="['计息方式', '付息方式']"
          :contentArr="[entity.jxfs, entity.fxfs]"
        ></cTableItem>
        <cTableItem
          :titleArr="['票面利率（%）', '发行收益率（%）']"
          :contentArr="[entity.pmll, entity.fxsyl]"
        ></cTableItem>
        <cTableItem
          :titleArr="['预期收益率（%）', '参考收益率（%）']"
          :contentArr="[entity.yqsyl, entity.cksyl]"
        ></cTableItem>
        <cTableItem
          :titleArr="['受托机构', '管理人简称']"
          :contentArr="[entity.stjg, entity.glrjc]"
        ></cTableItem>
        <div class="more" @click="getMore" v-if="!isMore">点击展开更多</div>
      </div>
      <div style="display: block" v-show="isMore">
        <cTableItem
          :titleArr="['首次发行范围', '标准券折算率']"
          :contentArr="[entity.scfxfw, entity.bzqzsl]"
        ></cTableItem>
        <cTableItem
          :titleArr="['兑付手续费率（%）', '债券估值（元）']"
          :contentArr="[entity.dfsxfl, entity.zqgz]"
        ></cTableItem>
        <cTableItem
          :titleArr="['剩余本金值', '挂牌方式']"
          :contentArr="[entity.sybjz, entity.gpfs]"
        ></cTableItem>
        <cTableItem
          :titleArr="['本息状态', '交易方式']"
          :contentArr="[entity.bxzt, entity.jyfs]"
        ></cTableItem>
        <cTableItem
          :titleArr="['发行手续费率（%）', '主承销商简称']"
          :contentArr="[entity.fxsxfl, entity.zcxsjc]"
        ></cTableItem>
        <cTableItem
          :titleArr="['银行间代码', '主承销商联系方式']"
          :contentArr="[entity.yxjdm, entity.zcxslxfs]"
        ></cTableItem>
        <cTableItem
          :titleArr="['上交所上市总量（亿元）', '标的证券代码']"
          :contentArr="[entity.sjssszl, entity.bdzqdm]"
        ></cTableItem>
        <cTableItem
          :titleArr="['首次划款日', '标的证券简称']"
          :contentArr="[entity.schkr, entity.bdzqjc]"
        ></cTableItem>
        <cTableItem
          :titleArr="['转让服务起始日期', '发起人类型']"
          :contentArr="[entity.zrfwqsrq, entity.fqrlx]"
        ></cTableItem>
        <cTableItem
          :titleArr="['计息基础', '管理人类型']"
          :contentArr="[entity.jxjc, entity.glrlx]"
        ></cTableItem>
        <cTableItem
          :titleArr="['适用市场', '审核机构']"
          :contentArr="[entity.sysc, entity.shjg]"
        ></cTableItem>
        <cTableItem
          :titleArr="['付息频率（月）', '备案机构']"
          :contentArr="[entity.fxpl, entity.bajg]"
        ></cTableItem>
        <cTableItem
          :titleArr="['基准利率名', '投资人类型']"
          :contentArr="[entity.jzllm, entity.tzrlx]"
        ></cTableItem>
        <cTableItem
          :titleArr="['基准利率（%）', '交易平台']"
          :contentArr="[entity.jzll, entity.jypt]"
        ></cTableItem>
        <cTableItem
          :titleArr="['基准利差（%）', '是否支持转托管']"
          :contentArr="[entity.jzlc, entity.sfzcztg]"
        ></cTableItem>
        <cTableItem
          :titleArr="['浮动利差（%）', '是否支持质押']"
          :contentArr="[entity.fdlc, entity.sfzczy]"
        ></cTableItem>
        <cTableItem
          :titleArr="['托管机构', '产品特点']"
          :contentArr="[entity.tgjg, entity.cptd]"
        ></cTableItem>
        <cTableItem
          :titleArr="['专项品种', '选择权']"
          :contentArr="[entity.zxpz, entity.xzq]"
        ></cTableItem>
      </div>
      <div class="title" v-if="entity.xqList && entity.xqList.length">
        行权记录
      </div>
      <div class="dotBox" v-if="entity.xqList && entity.xqList.length">
        <div class="item" v-for="(item, index) in entity.xqList" :key="index">
          <div class="dot"></div>
          <div>{{ item.xqrq }}</div>
          <div>{{ item.xqlx }}</div>
        </div>
      </div>
      <div class="title" v-if="entity.xfList && entity.xfList.length">
        续发信息
      </div>
      <Table
        border
        tooltip-theme="light"
        v-if="entity.xfList && entity.xfList.length"
        :columns="columns"
        :data="entity.xfList"
      ></Table>
      <div class="title">相关公告</div>
      <div
        class="noticeBox"
        v-for="(item, index) in entity.ggList"
        :key="index"
      >
        <div class="arrow"></div>
        <div class="sign">{{ item.gglx }}</div>
        <div class="content">
          <a :href="item.url">
            <Tooltip
              :content="item.ggbt"
              max-width="1200"
              style="width: 100%"
              word-wrap
              theme="light"
              placement="top"
              >{{ item.ggbt }}
            </Tooltip>
          </a>
        </div>
        <div class="time">{{ item.ggrq }}</div>
      </div>
    </div>
    <div v-if="tab == 3" style="margin-bottom: 100px">
      <div class="title">进度信息明细</div>
      <div style="margin-top: 20px">
        <div class="table-title">
          {{entity.zqmc}}
        </div>
        <cTableItem
          :titleArr="['债券品种', '债券名称']"
          :contentArr="[entity.zqpz, entity.zqmc]"
        ></cTableItem>
        <cTableItem
          :titleArr="['地区', '行业分类']"
          :contentArr="[entity.dq, entity.xyfl]"
        ></cTableItem>
        <cTableItem
          :titleArr="['拟发行金额（亿元）', '发行人']"
          :contentArr="[entity.nfxje, entity.fxr]"
        ></cTableItem>
        <cTableItem
          :titleArr="['承销商/管理人', '交易所确认文件文号']"
          :contentArr="[entity.cxsGlr, entity.jysqrwjwh]"
        ></cTableItem>
        <cTableItem
          :titleArr="['项目状态', '更新日期']"
          :contentArr="[entity.xmzt, entity.gxrq]"
        ></cTableItem>
        <cTableItem
          :titleArr="['数据来源', '']"
          :contentArr="[entity.sjly, '']"
        ></cTableItem>
      </div>
      <div class="title">文件披露</div>
      <div style="margin: 20px 0">
        <div>
          <div class="line-title topline" style="width: 620px">披露文件</div>
          <div class="line-title topline">{{ xxpl.sbgsj }}</div>
          <div class="line-title topline">{{ xxpl.fkhfgsj }}</div>
          <div class="line-title topline">{{ xxpl.fjgsj }}</div>
        </div>
        <div class="table-title">本次公司债券发行的募集文件</div>
        <div>
          <div class="line-title" style="width: 620px">
            {{ mjsms.plwjm == "" ? "募集说明书" : mjsms.plwjm }}
          </div>
          <div class="line-content">{{ mjsms.sbgsj }}</div>
          <div class="line-content">{{ mjsms.fkhfgsj }}</div>
          <div class="line-content">{{ mjsms.fjgsj }}</div>
        </div>
        <div>
          <div class="line-title" style="width: 620px">
            {{ mjsmszy.plwjm == "" ? "募集说明书摘要" : mjsmszy.plwjm }}
          </div>
          <div class="line-content">{{ mjsmszy.sbgsj }}</div>
          <div class="line-content">{{ mjsmszy.fkhfgsj }}</div>
          <div class="line-content">{{ mjsmszy.fjgsj }}</div>
        </div>
        <div class="table-title">
          发行人关于本次公司债券发行的申请和授权文件
        </div>
        <div>
          <div class="line-title" style="width: 620px">
            {{
              gdhy1.plwjm == ""
                ? "发行人有权机构关于本期公开发行公司债券发行事项的股东会决议"
                : gdhy1.plwjm
            }}
          </div>
          <div class="line-content">{{ gdhy1.sbgsj }}</div>
          <div class="line-content">{{ gdhy1.fkhfgsj }}</div>
          <div class="line-content">{{ gdhy1.fjgsj }}</div>
        </div>
        <div>
          <div class="line-title" style="width: 620px">
            {{
              gdhy2.plwjm == ""
                ? "发行人有权机构关于本期公开发行公司债券发行事项的股东会决议"
                : gdhy2.plwjm
            }}
          </div>
          <div class="line-content">{{ gdhy2.sbgsj }}</div>
          <div class="line-content">{{ gdhy2.fkhfgsj }}</div>
          <div class="line-content">{{ gdhy2.fjgsj }}</div>
        </div>
        <div class="table-title">中介机构关于本次公司债券发行的文件</div>
        <div>
          <div class="line-title" style="width: 620px">
            {{ gdhy3.plwjm == "" ? "主承销商核查意见" : gdhy3.plwjm }}
          </div>
          <div class="line-content">{{ gdhy3.sbgsj }}</div>
          <div class="line-content">{{ gdhy3.fkhfgsj }}</div>
          <div class="line-content">{{ gdhy3.fjgsj }}</div>
        </div>
        <div>
          <div class="line-title" style="width: 620px">
            {{ gdhy4.plwjm == "" ? "发行人律师出具的法律意见书" : gdhy4.plwjm }}
          </div>
          <div class="line-content">{{ gdhy4.sbgsj }}</div>
          <div class="line-content">{{ gdhy4.fkhfgsj }}</div>
          <div class="line-content">{{ gdhy4.fjgsj }}</div>
        </div>
        <div>
          <div class="line-title" style="width: 620px">
            {{
              gdhy5.plwjm == ""
                ? "资信评级机构为本次发行公司债券出具的资信评级报告"
                : gdhy5.plwjm
            }}
          </div>
          <div class="line-content">{{ gdhy5.sbgsj }}</div>
          <div class="line-content">{{ gdhy5.fkhfgsj }}</div>
          <div class="line-content">{{ gdhy5.fjgsj }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryJnzById, queryXmjdById } from "@/api/financial/debt";
import cTableItem from "@/components/cTableItem";
import { returnFloat } from "@/utils";

export default {
  name: "domesticDetail",
  data() {
    return {
      isMore: false,
      tab: 1,
      date1: "",
      columns: [
        {
          title: "续发行发行日",
          key: "xfxfxr",
        },
        {
          title: "续发行上市 交易日",
          key: "xfxssjyr",
        },
        {
          title: "计划续发行 总额(亿)",
          key: "jhxfxze",
        },
        {
          title: "实际续发行 总额(亿)",
          key: "sjxfxze",
        },
        {
          title: "续发行价格(元)",
          key: "xfxjg",
        },
        {
          title: "续发行收益率(%)",
          key: "xfxsyl",
        },
        {
          title: "付息兑付情况",
          key: "fxdfqk",
        },
        {
          title: "浮动利率变动情况",
          key: "fdllbdqk",
        },
      ],
      entity: {},
      fkyj: {},
      xxpl: {},
      mjsms: {},
      mjsmszy: {},
      gdhy1: {},
      gdhy2: {},
      gdhy3: {},
      gdhy4: {},
      gdhy5: {},
    };
  },
  components: {
    cTableItem,
  },
  created() {
    this.tab = this.$route.query.tab;
  },
  mounted() {
    this._get();
  },
  methods: {
    getMore() {
      this.isMore = true;
    },
    _get() {
      let arrTo2 = [
        "sjssszl",
        "mz",
        "fxjg",
        "jhfxze",
        "sjfxze",
        "fxl",
        // "fxpl",
        "pmll",
        "fxsyl",
        "cksyl",
        "yqsyl",
        "jzll",
        "jzlc",
        "fdlc",
        "fxsxfl",
        "dfsxfl",
        "sybjz",
        "bzqzsl",
      ];
      if (this.tab == 1) {
        queryJnzById({
          id: this.$route.query.id,
        }).then((data) => {
          arrTo2.forEach((i) => {
            if (!isNaN(data[i]) && data[i]) {
              data[i] = returnFloat(data[i], 2);
            }
          });
          this.entity = data;
        });
      } else if (this.tab == 3) {
        queryXmjdById({
          id: this.$route.query.id,
        }).then((data) => {
          arrTo2.forEach((i) => {
            if (!isNaN(data[i]) && data[i]) {
              data[i] = returnFloat(data[i], 2);
            }
          });
          this.entity = data;
          if (this.entity.fkyjList.length > 0) {
            this.fkyj = this.entity.fkyjList[0];
          }
          if (this.entity.xxplList.length > 0) {
            this.xxpl = this.entity.xxplList[0];
            this.entity.xxplList.forEach((item) => {
              if (item.plwjlx == "11") {
                this.mjsms = item;
              }
              if (item.plwjlx == "12") {
                this.mjsmszy = item;
              }
              if (item.plwjlx == "21") {
                this.gdhy1 = item;
              }
              if (item.plwjlx == "22") {
                this.gdhy2 = item;
              }

              if (item.plwjlx == "31") {
                this.gdhy3 = item;
              }

              if (item.plwjlx == "32") {
                this.gdhy4 = item;
              }

              if (item.plwjlx == "33") {
                this.gdhy5 = item;
              }
            });
          }
        });
      }
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/common.scss";
.domesticDetail {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #fff;
  padding: 20px;
  position: relative;
  .goback {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #00b6ff;
    font-size: 36px;
    transform: rotateY(180deg);
    cursor: pointer;
  }
  div {
    h1 {
      font-size: 20px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
      line-height: 26px;
      /* margin-bottom: 20px; */
    }
    .title {
      font-size: 18px;
      font-weight: bold;
      color: rgba(21, 98, 214, 1);
      margin: 20px 0;
      border-left: 4px solid #1562d6;
      height: 18px;
      line-height: 18px;
      padding-left: 10px;
    }
    div.dotBox {
      @include flex(flex-start);
      .item {
        @include flex(flex-start);
        color: #333333;
        font-size: 14px;
        width: 275px;
        height: 40px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(214, 215, 217, 1);
        margin-right: 10px;
        /* padding:10px 10px 100px; */
        .dot {
          width: 10px;
          height: 10px;
          background: rgba(0, 182, 255, 1);
          border-radius: 50%;
        }
        div {
          margin-left: 10px;
        }
      }
    }
    div.more {
      height: 40px;
      background: rgba(250, 250, 253, 1);
      color: #00b6ff;
      line-height: 40px;
      font-size: 14px;
      text-align: center;
      margin-top: 10px;
      cursor: pointer;
    }
    div {
      font-size: 14px;
      color: rgba(51, 51, 51, 1);
      div.table-title {
        @include flex(flex-start);
        padding-left: 20px;
        background: rgba(231, 240, 252, 1);
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        height: 40px;
        line-height: 40px;
        border: 1px solid rgba(214, 215, 217, 1);
      }
      div {
        @include flex;
        height: 40px;
        line-height: 40px;
        .line-title:nth-of-type(1) {
          border-left: 1px solid rgba(214, 215, 217, 1);
        }
        .line-title {
          background: rgba(250, 250, 253, 1);
          width: 414px;
          border-right: 1px solid rgba(214, 215, 217, 1);
          border-bottom: 1px solid rgba(214, 215, 217, 1);
          word-break: keep-all;
        }
        .topline {
          border-top: 1px solid rgba(214, 215, 217, 1);
          border-bottom: none;
        }
        .line-content {
          background: #fff;
          width: 414px;
          border-right: 1px solid rgba(214, 215, 217, 1);
          border-bottom: 1px solid rgba(214, 215, 217, 1);
        }
      }
    }
    .noticeBox {
      border-bottom: 1px dashed rgba(214, 215, 217, 1);
      height: 44px;
      width: 100%;
      line-height: 44px;
      @include flex(flex-start);
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: rgba(51, 51, 51, 1);
      .arrow {
        width: 0px;
        height: 0px;
        border-left: 8px solid #aaa;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 8px solid transparent;
      }
      .sign {
        width: 60px;
        height: 24px;
        white-space: nowrap;
        background: rgba(246, 250, 255, 1);
        border-radius: 2px;
        border: 1px solid rgba(21, 98, 214, 1);
        color: #1562d6;
        line-height: 24px;
        margin-right: 10px;
      }
      .content {
        flex: 2;
        justify-content: flex-start;
        overflow: hidden;
        white-space: nowrap;
      }
      .time {
        font-size: 14px;
        color: rgba(153, 153, 153, 1);
      }
    }
  }
}
</style>
